import React from "react";
import TinySlider from "tiny-slider-react";

import avatar1 from "../assets/images/client/01.jpg";
import avatar2 from "../assets/images/client/02.jpg";
import avatar3 from "../assets/images/client/03.jpg";
import avatar4 from "../assets/images/client/04.jpg";

import 'tiny-slider/dist/tiny-slider.css';

const settings = {
    controls: false,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    speed: 400,
    controlsText: ['<i class="mdi mdi-chevron-left "></i>', '<i class="mdi mdi-chevron-right"></i>'],
    gutter: 0,
    responsive: {

        768: {
            items: 2
        }
    },
};
export default function Review() {
    const review = [
        {
            id: '1',
            profile: avatar1,
            name: 'Calvin Carlo',
            designation: "Manager",
            description: "According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero Launch your campaign and benefit from our expertise."
        },
        {
            id: '2',
            profile: avatar2,
            name: 'Christa Smith',
            designation: "Manager",
            description: "According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero Launch your campaign and benefit from our expertise."
        }, {
            id: '3',
            profile: avatar3,
            name: 'Jemina CLone',
            designation: "Manager",
            description: "According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero Launch your campaign and benefit from our expertise."
        }, {
            id: '4',
            profile: avatar4,
            name: 'Smith Vodka',
            designation: "Manager",
            description: "According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero Launch your campaign and benefit from our expertise."
        }
    ]

    return (
        <>
<div>
  <h1 className="text-3xl text-center font-bold">Report by Indian Bureau of Mines</h1>
</div>

            {/* Review Start  */}
            <div className="m-4 overflow-x-auto" id="testimonial">
      <table className="min-w-full bg-white border border-black">
        <thead>
          <tr>
            <th className="py-2 border-2 border-black" rowSpan={4}>SR.No</th>
            <th className="py-2 border-2 border-black" rowSpan={4}>Sample Description</th>
            <th
            //   colSpan="2"
              rowSpan="4"
              className="py-2 border-2 border-l-0 border-black"
            >
              IBM Ref
            </th>
            <th colSpan="10" className="py-2 border-2 border-black">
              Values in Percentage (%)
            </th>
          </tr>
          <tr>
            {/* <td className="py-2 border-2 border-black">AlzO3</td>
            <td className="py-2 border-2 border-black">SiO2</td> */}
            <td className="py-2 border-2 border-black">AlzO3</td>
            <td className="py-2 border-2 border-black">SiO2</td>
            <td className="py-2 border-2 border-black">Fe2O3</td>
            <td className="py-2 border-2 border-black">TiO2</td>
            <td className="py-2 border-2 border-black">CaO</td>
            <td className="py-2 border-2 border-black">MgO</td>
            <td className="py-2 border-2 border-black">Na2O</td>
            <td className="py-2 border-2 border-black">K2O</td>
            <td className="py-2 border-2 border-black">LOI</td>
            <td className="py-2 border-2 border-black">Moisture</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="py-2 border-2 border-black" >1.</td>
            <td className="py-2 border-2 border-black" >Bentonite Sample, Karnataka</td>
            <td className="py-2 border-2 border-t-0 border-l-0 border-r-2 border-black">CB-109</td>
            <td className="py-2 border-2 border-t-0 border-l-0 border-r-2 border-black">8.89</td>
            <td className="py-2 border-2 border-t-0 border-l-0 border-r-2 border-black">46.23</td>
            <td className="py-2 border-2 border-t-0 border-l-0 border-r-2 border-black">11.84</td>
            <td className="py-2 border-2 border-t-0 border-l-0 border-r-2 border-black">1.36</td>
            <td className="py-2 border-2 border-t-0 border-l-0 border-r-2 border-black">10.91</td>
            <td className="py-2 border-2 border-t-0 border-l-0 border-r-2 border-black">3.67</td>
            <td className="py-2 border-2 border-t-0 border-l-0 border-r-2 border-black">0.09</td>
            <td className="py-2 border-2 border-t-0 border-l-0 border-r-2 border-black">0.16</td>
            <td className="py-2 border-2 border-t-0 border-l-0 border-r-2 border-black">16.11</td>
            <td className="py-2 border-2 border-t-0 border-l-0 border-r-2 border-black">5.95</td>
          </tr>
        </tbody>
      </table>
    </div>
        </>
    )

}



