import React, { useState } from "react";
import { Link as Link2 } from "react-router-dom";
import logo from '../assets/images/logo.png'
import AboutImage from "../assets/images/about.jpg";
import ModalVideo from "react-modal-video";
import "../../node_modules/react-modal-video/scss/modal-video.scss";
import pamplate from '../assets/images/pamplate.png'

export default function About() {
  const [isOpen, setOpen] = useState(false)

  return (
    <>
      <section className="relative py-16 md:py-24 bg-gray-50 dark:bg-slate-800" id="about">
        <div className="container mx-auto">
          <div className="grid items-center grid-cols-1 gap-10 lg:grid-cols-12 md:grid-cols-2">
            <div className="lg:col-span-5">
              <div className="relative">
                <img src={logo} className="relative rounded-lg shadow-lg" alt="" />
                <div className="absolute text-center bottom-2/4 translate-y-2/4 start-0 end-0">
                  {/* <Link2 to="#" onClick={() => setOpen(true)} className="inline-flex items-center justify-center w-20 h-20 text-orange-600 bg-white rounded-full shadow-lg lightbox shadow-slate-100 dark:shadow-slate-800 dark:bg-slate-900">
                    <i className="inline-flex items-center justify-center text-2xl mdi mdi-play"></i>
                  </Link2> */}
                </div>
              </div>
            </div>
            {/* end col */}

            <div className="lg:col-span-7">
              <div className="lg:ms-7">
                <h6 className="mb-2 text-base text-center font-medium text-orange-600 uppercase">Who We Are ?</h6>
                <h3 className="mb-4 text-xl text-center font-medium md:text-2xl dark:text-white">Welcome to JS MINERALS
</h3>

                <p className="max-w-2xl mx-auto text-slate-400 dark:text-slate-300">Welcome to JS MINERALS, where excellence meets integrity in the world of minerals and natural resources. Founded with a passion for sustainable practices and a commitment to quality, JS MINERALS has emerged as a leading name in the industry, delivering unparalleled products and services. At JS MINERALS, we envision a future where the Earth's treasures are harnessed responsibly, enriching lives while preserving the planet for generations to come. Our vision is to be a global leader in the extraction and distribution of minerals, setting industry standards for ethical practices and environmental stewardship.


</p>

                <div className="relative mt-10">
                  <a href="#portfolio" className="text-white bg-orange-600 border-orange-600 rounded-md btn hover:bg-orange-700 hover:border-orange-700">View Portfolio</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End --> */}
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId="yba7hPeTSjk"
        onClose={() => setOpen(false)}
      />
    </>
  );

}

