import React, { useState, useEffect } from 'react';  // Add this import
import { Link } from "react-scroll";
import { Link as Link2 } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

import LogoLight from "../assets/images/logo-light.png";
import LogoDark from "../assets/images/logo-dark.png";
import Typed from "typed.js";

import BackgroudImage from "../assets/images/1.jpeg";
import Services from "../component/Services";
import About from "../component/About";
import Pricing from "../component/Pricing";
import Blog from "../component/Blog";
import Contact from "../component/Contact";
import Footer from "../component/Footer";
import Portfolio from "../component/Portfolio";
import Review from "../component/Testimonial";
import Switcher from "../component/Switcher";
import logo from '../assets/images/logo.png'
import Whatsapp from "../component/Whatsapp";
import im1 from "../assets/images/1.jpeg";
import im2 from "../assets/images/2.jpeg";
import im3 from "../assets/images/3.jpeg";
import im4 from "../assets/images/4.jpeg";
import im5 from "../assets/images/5.jpeg";
import im6 from "../assets/images/6.jpeg";
import im7 from "../assets/images/7.jpeg";
import im8 from "../assets/images/8.jpeg";
import popup from '../assets/images/popup.jpg'
/**
 * Index Component
 */
export default function Index() {
  const [isModalOpen, setModalOpen] = useState(true);

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const [isOpen, setMenu] = useState(true);
  window.addEventListener("scroll", windowScroll);

  const toggleMenu = () => {
    setMenu(!isOpen)
  }

  // setTimeout(() => {
  //   new Typed("#typed", {
  //     strings: [
  //       "<b>Business</b>",
  //       "<b>Startups</b>",
  //       "<b>Digital Agency</b>",
  //       "<b>Marketing</b>",
  //     ],
  //     backDelay: 2000,
  //     loop: true,
  //     startDelay: 300,
  //     typeSpeed: 100,
  //     backSpeed: 100,
  //   });
  // }, 500);


  /**
   * Window scroll
   */
  function windowScroll() {
    const navbar = document.getElementById("navbar");
    if (
      document.body.scrollTop >= 50 ||
      document.documentElement.scrollTop >= 50
    ) {
      navbar.classList.add("is-sticky");
    } else {
      navbar.classList.remove("is-sticky");
    }
  }

  const images = [
   im1,im2,im3,im4,im5,im6,im7,im8
  ];

  const ImageSlider = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
      const intervalId = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 3000);

      return () => clearInterval(intervalId);
    }, []);
    return (
      // <div className="relative ">
        <div className="flex items-center justify-center h-96 mt-56 max-lg:mt-0">
          {images.map((img, index) => (
            <img
              key={index}
              src={img}
              alt={`slide-${index}`}
              // relative table w-full bg-center bg-cover py-36 lg:py-64
              className={`absolute w-full bg-cover transition-opacity duration-500 ${
                index === currentIndex ? 'opacity-100' : 'opacity-0'
              }`}
            />
          ))}
        </div>
      // </div>
    );
  };



  return (
    <>
    <div>
      {isModalOpen && (
        <div className="modal-overlay" style={{ display: 'block', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.5)', zIndex: '1001' }}>
          <div className="modal" style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', padding: '20px', background: 'white', borderRadius: '5px', zIndex: '1001' }}>
            <h1 className="text-2xl font-semibold text-center mb-5 max-md:text-sm">Welcome to JS MINERALS</h1>

            {/* Display your image */}
            <img src={popup} alt="Your Image Alt Text" style={{ width: '100%', marginBottom: '10px', zIndex: '1001' }} />

            <div className="text-center">
              {/* Close the modal */}
              <button onClick={handleCloseModal} className="p-2 cursor-pointer bg-gray-400 text-white rounded-md">
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      {/* The rest of your website content goes here */}
      <h1>Your Website Content</h1>
      <p>This is the rest of your website...</p>
    </div>
      <div>
        <nav className="navbar bg-white" id="navbar">
          <div className="container flex flex-wrap items-center justify-center">
            <a className="navbar-brand" href="index.html">
              <span className="inline-block dark:hidden">
                <img src={logo} className="h-20 l-dark" alt="" />
                <img src={logo} className="h-20 l-light" alt="" />
              </span>
              {/* <img
                src={LogoLight}
                className="hidden dark:inline-block"
                alt=""
              /> */}
            </a>

            <div className="flex items-center justify-center nav-icons lg_992:order-2 ms-auto">
              <ul className="mb-0 list-none menu-social">
                <li className="inline ms-1">
                  <Link2 to="#">
                    <span className="login-btn-primary">
                      <span className="text-white bg-blue-600 border-blue-600 rounded-full btn btn-sm btn-icon">
                        <i className="uil uil-facebook"></i>
                      </span>
                    </span>
                    <span className="login-btn-light">
                      <span className="text-white bg-blue-600 border-blue-600 rounded-full btn btn-sm btn-icon">
                        <i className="uil uil-facebook"></i>
                      </span>
                    </span>
                  </Link2>
                </li>
                <li className="inline ms-1">
                  <Link2 to="#">
                    <span className="login-btn-primary">
                      <span className="text-white bg-blue-400 border-blue-400 rounded-full btn btn-sm btn-icon ">
                        <i className="uil uil-twitter"></i>
                      </span>
                    </span>
                    <span className="login-btn-light">
                      <span className="text-white bg-blue-400 border-blue-400 rounded-full btn btn-sm btn-icon ">
                        <i className="uil uil-twitter"></i>
                      </span>
                    </span>
                  </Link2>
                </li>
                <li className="inline ms-1">
                  <a href="https://www.instagram.com/jsmineralstrading/" target='_blank'>
                    <span className="login-btn-primary">
                      <span className="text-white bg-pink-400 border-pink-400 rounded-full btn btn-sm btn-icon ">
                        <i className="uil uil-instagram"></i>
                      </span>
                    </span>
                    <span className="login-btn-light">
                      <span className="text-white bg-pink-400 border-pink-400 rounded-full btn btn-sm btn-icon ">
                        <i className="uil uil-instagram"></i>
                      </span>
                    </span>
                  </a>
                </li>
              </ul>
              <button
                type="button"
                className="inline-flex items-center collapse-btn ms-3 text-dark dark:text-white lg_992:hidden"
                onClick={toggleMenu}
              >
                <span className="sr-only">Navigation Menu</span>
                <i className="mdi mdi-menu mdi-24px"></i>
              </button>
            </div>

            <div
  className={`${isOpen === true ? 'hidden' : 'block'} navigation lg_992:order-1 lg_992:flex`}
  id="menu-collapse"
>
              <ul className="navbar-nav" id="navbar-navlist">
                <ScrollLink
                  className="nav-item"
                  to="home"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={toggleMenu} 
                >
                  <span className="nav-link">Home</span>
                </ScrollLink>
                <ScrollLink
                  className="nav-item"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                  to="about"
                  onClick={toggleMenu} 

                >
                  <span className="nav-link">About us</span>
                </ScrollLink>
                {/* <Link
                  className="nav-item"
                  to="features"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  <span className="nav-link">Services</span>
                </Link> */}
                <ScrollLink
                  className="nav-item"
                  to="portfolio"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={toggleMenu} 

                >
                  <span className="nav-link">Gallery</span>
                </ScrollLink>
                <ScrollLink
                  className="nav-item"
                  to="testimonial"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={toggleMenu} 

                >
                  <span className="nav-link">Report</span>
                </ScrollLink>
          
                <ScrollLink
                  className="nav-item"
                  to="blog"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={toggleMenu} 

                >
                  <span className="nav-link">Products</span>
                </ScrollLink>
                <ScrollLink
                  className="nav-item"
                  to="pricing"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={toggleMenu} 

                >
                  <span className="nav-link">Production</span>
                </ScrollLink>
                <ScrollLink
                  className="nav-item"
                  to="contact"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                  onClick={toggleMenu} 

                >
                  <span className="nav-link">Contact us</span>
                </ScrollLink>
                <a
                  className="nav-item"
                  href="https://wa.me/+917406555055"
                  activeclassname="active"
                  spy={true}
                  smooth={true}
                  duration={500}
                  
                >
                  <span className="nav-link">Enquiry</span>
                </a>
              </ul>
            </div>
          </div>
        </nav>
        <ImageSlider images={images} />

   <Whatsapp/>

        {/* About section */}
        <About />

        {/* Service section */}
        <Services />

        {/* Portfolio section */}
        <Portfolio />

        {/* Review section */}
        <Review />

   

        {/* Blog section */}
        <Blog />
             {/* Pricing section */}
             <Pricing />

        {/* Contact section */}
        <Contact />

        {/* Footer section */}
        <Footer />

        {/* Switcher section */}
        <Switcher />
      </div>

    </>
  );

}

