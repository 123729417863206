import React, { useState } from "react";
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";
import { Link } from "react-router-dom";

import Image1 from "../assets/images/portfolio/1.jpg";
import Image2 from "../assets/images/portfolio/2.jpg";
import Image3 from "../assets/images/portfolio/3.jpg";
import Image4 from "../assets/images/portfolio/4.jpg";
import Image5 from "../assets/images/portfolio/5.jpg";
import Image6 from "../assets/images/portfolio/6.jpg";
import Image7 from "../assets/images/portfolio/7.jpg";
import Image8 from "../assets/images/portfolio/8.jpg";
import im1 from "../assets/images/1.jpeg";
import im2 from "../assets/images/2.jpeg";
import im3 from "../assets/images/3.jpeg";
import im4 from "../assets/images/4.jpeg";
import im5 from "../assets/images/5.jpeg";
import im6 from "../assets/images/6.jpeg";
import im7 from "../assets/images/7.jpeg";
import im8 from "../assets/images/8.jpeg";
import im9 from "../assets/images/9.jpeg";
import im10 from "../assets/images/10.jpeg";
import im11 from "../assets/images/11.jpeg";
import im12 from "../assets/images/12.jpeg";
import im13 from "../assets/images/13.jpeg";
import im14 from "../assets/images/14.jpeg";
import im15 from "../assets/images/15.jpeg";
import im16 from "../assets/images/16.jpeg";
import im17 from "../assets/images/17.jpeg";
import im18 from "../assets/images/18.jpeg";
import im28 from "../assets/images/28.jpeg";
import im30 from "../assets/images/30.jpeg";

import im31 from "../assets/images/31.jpeg";
import im32 from "../assets/images/32.jpeg";
import im33 from "../assets/images/33.jpeg";
import im34 from "../assets/images/34.jpeg";
import im35 from "../assets/images/35.jpeg";
import im36 from "../assets/images/36.jpeg";
import im37 from "../assets/images/37.jpeg";
import im38 from "../assets/images/38.jpeg";
import im39 from "../assets/images/39.jpeg";
import im40 from "../assets/images/40.jpeg";
import im41 from "../assets/images/41.jpeg";
import im45 from "../assets/images/45.jpeg";
import im42 from "../assets/images/42.jpeg";
import im43 from "../assets/images/43.jpeg";
import im44 from "../assets/images/44.jpeg";
import im46 from "../assets/images/46.jpeg";

import CTABackground from "../assets/images/bg/cta.png";

import new1 from "../assets/images/new1.jpg";
import new2 from "../assets/images/new2.jpg";
import new3 from "../assets/images/new3.jpg";
import new4 from "../assets/images/new4.jpg";

const images = [im1,im3,im10,im12,im13,im14,im16,im17];
const images1 = [im45,im40,im35,new1,new2,new3,im38,im41];

/**
 * Portfolio section
 */
export default function Portfolio() {
  const [photoIndex, setActiveIndex] = useState(0);
  const [isOpen, setOpen] = useState(false);

  const handleCLick = (index) => {
    setActiveIndex(index);
    setOpen(true);
  };

  const projectList = [
    {
      image: im2,
    },
    {
      image: im3,
    },

    {
      image: im10,
    },

    {
      image: im12,
    },
    {
      image: im13,
    },
    {
      image: im14,
    },

    {
      image: im16,
    },
    {
      image: im17,
    },
  ];
  const projectList1 = [
    {
      image1: im45,
    },
    {
      image1: im40,
    },

    {
      image1: im35,
    },

    {
      image1: new1,
    },
    {
      image1: new2,
    },
    {
      image1: new3,
    },

    {
      image1: im38,
    },

    {
      image1: im41,
    },
  ];
  return (
    <>
      {/* Project Start  */}
      

      <section
        className="relative py-16 md:py-24 bg-gray-50 dark:bg-slate-800 active"
        id="portfolio"
      >
        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h6 className="mb-2 text-base font-medium text-orange-600 uppercase">
              Gallery
            </h6>
            <h3 className="mb-4 text-xl font-bold md:text-3xl dark:text-white">
              {" "}
              UNIT - 1
            </h3>

            <p className="max-w-xl mx-auto font-bold text-slate-400 dark:text-slate-300">
              Sulepet Talukha Chincholi Karnataka
            </p>
          </div>

          <div className="grid grid-cols-1 gap-6 mt-8 lg:grid-cols-4 md:grid-cols-2">
            {projectList.map((item, index) => (
              <div
                className="relative overflow-hidden rounded-md shadow-sm group"
                key={index}
              >
                <img
                  src={item.image}
                  className="transition duration-500 group-hover:origin-center group-hover:scale-110 group-hover:rotate-3"
                  alt="workimage"
                />
                <div className="absolute inset-0 z-0 transition duration-500 opacity-50 group-hover:bg-black"></div>

                <div className="content">
                  <div className="absolute z-10 transition-all duration-500 opacity-0 icon group-hover:opacity-100 top-4 end-4">
                    <Link
                      to="#"
                      onClick={() => handleCLick(index)}
                      className="text-white bg-orange-600 border-orange-600 rounded-full btn hover:bg-orange-700 hover:border-orange-700 btn-icon lightbox"
                    >
                      <i className="uil uil-camera"></i>
                    </Link>
                  </div>
                  <div className="absolute z-10 transition-all duration-500 opacity-0 group-hover:opacity-100 bottom-4 start-4">
                    <Link
                      to="#"
                      className="font-medium text-white transition duration-500 h6 text-md hover:text-orange-600"
                    >
                      {item.title}
                    </Link>
                    <p className="mb-0 text-slate-100 tag">{item.subtext}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {isOpen && (
            <Lightbox
              mainSrc={images[photoIndex]}
              nextSrc={images[(photoIndex + 1) % images.length]}
              prevSrc={images[(photoIndex + images.length - 1) % images.length]}
              onCloseRequest={() => setOpen({ isOpen: false })}
              onMovePrevRequest={() =>
                setActiveIndex((photoIndex + images.length - 1) % images.length)
              }
              onMoveNextRequest={() =>
                setActiveIndex((photoIndex + 1) % images.length)
              }
            />
          )}
        </div>
      </section>
      <section
        className="relative py-16 md:py-24 bg-gray-50 dark:bg-slate-800 active"
        id="portfolio"
      >
        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h6 className="mb-2 text-base font-medium text-orange-600 uppercase">
              Gallery
            </h6>
            <h3 className="mb-4 text-xl font-bold md:text-3xl dark:text-white">
              UNIT - 2
            </h3>

            <p className="max-w-xl mx-auto text-slate-400 dark:text-slate-300">
              Kalaburagi Factory
            </p>
          </div>

          <div className="grid grid-cols-1 gap-6 mt-8 lg:grid-cols-4 md:grid-cols-2">
            {projectList1.map((item, index) => (
              <div
                className="relative overflow-hidden rounded-md shadow-sm group"
                key={index}
              >
                <img
                  src={item.image1}
                  className="transition duration-500 group-hover:origin-center group-hover:scale-110 group-hover:rotate-3"
                  alt="workimage"
                />
                <div className="absolute inset-0 z-0 transition duration-500 opacity-50 group-hover:bg-black"></div>

                <div className="content">
                  <div className="absolute z-10 transition-all duration-500 opacity-0 icon group-hover:opacity-100 top-4 end-4">
                    <Link
                      to="#"
                      onClick={() => handleCLick(index)}
                      className="text-white bg-orange-600 border-orange-600 rounded-full btn hover:bg-orange-700 hover:border-orange-700 btn-icon lightbox"
                    >
                      <i className="uil uil-camera"></i>
                    </Link>
                  </div>
                  <div className="absolute z-10 transition-all duration-500 opacity-0 group-hover:opacity-100 bottom-4 start-4">
                    <Link
                      to="#"
                      className="font-medium text-white transition duration-500 h6 text-md hover:text-orange-600"
                    >
                      {item.title}
                    </Link>
                    <p className="mb-0 text-slate-100 tag">{item.subtext}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {isOpen && (
            <Lightbox
              mainSrc={images1[photoIndex]}
              nextSrc={images1[(photoIndex + 1) % images1.length]}
              prevSrc={images1[(photoIndex + images1.length - 1) % images1.length]}
              onCloseRequest={() => setOpen({ isOpen: false })}
              onMovePrevRequest={() =>
                setActiveIndex((photoIndex + images1.length - 1) % images1.length)
              }
              onMoveNextRequest={() =>
                setActiveIndex((photoIndex + 1) % images1.length)
              }
            />
          )}
        </div>
      </section>
  
      {/* Project End  */}

      {/* <section
                style={{ backgroundImage: `url(${CTABackground})` }}
                className="relative table w-full py-24 bg-center bg-cover">
                <div className="absolute inset-0 bg-black opacity-80"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-6 text-2xl font-medium text-white md:text-3xl">Ready to start your next web project now?</h3>

                        <p className="max-w-xl mx-auto text-white opacity-50">Launch your campaign and benefit from our expertise on designing and managing conversion centered Tailwind CSS html page.</p>

                        <div className="relative mt-10">
                            <Link to="#" className="text-white bg-orange-600 border-orange-600 rounded-md btn hover:bg-orange-700 hover:border-orange-700">Get Started !</Link>
                        </div>
                    </div>
                </div>
            </section> */}
    </>
  );
}
