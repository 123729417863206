import React from "react";
import map from '../assets/images/image.png'


import CountUp from 'react-countup';
export default function Contact() {
    return (
        <>
            {/* Start */}
            <section className="relative py-16 md:py-24 bg-gray-50 dark:bg-slate-800" id="contact">
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h6 className="mb-2 text-base font-medium text-orange-600 uppercase">Contact us</h6>
                        <h3 className="mb-4 text-xl font-medium md:text-2xl dark:text-white">Get In Touch !</h3>

                        <p className="max-w-xl mx-auto text-slate-400 dark:text-slate-300">Contact us without hesitation we will reach out to you in no minute.</p>
                    </div>

                    <div className="grid items-center grid-cols-1 gap-6 mt-8 lg:grid-cols-12 md:grid-cols-2">
                        <div className="lg:col-span-8">
                            <div className="p-6 bg-white rounded-md shadow dark:bg-slate-900">
                                <form action="mailto:jsminerals5@gmail.com">
                                    <div className="grid lg:grid-cols-12 lg:gap-6">
                                        <div className="mb-5 lg:col-span-6">
                                            <input name="name" id="name" type="text" className="form-input" placeholder="Name :" />
                                        </div>

                                        <div className="mb-5 lg:col-span-6">
                                            <input name="email" id="email" type="email" className="form-input" placeholder="Email :" />
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-1">
                                        <div className="mb-5">
                                            <input name="subject" id="subject" className="form-input" placeholder="Subject :" />
                                        </div>

                                        <div className="mb-5">
                                            <textarea name="comments" id="comments" className="form-input textarea h-28" placeholder="Message :"></textarea>
                                        </div>
                                    </div>
                                    <button type="submit" id="submit" name="send" className="flex items-center justify-center text-white bg-orange-600 border-orange-600 rounded-md btn hover:bg-orange-700 hover:border-orange-700 h-11">Send Message</button>
                                </form>
                            </div>
                        </div>

                        <div className="lg:col-span-4">
                            <div className="lg:ms-8">
                                <div className="flex">
                                    <div className="mx-auto text-center icons">
                                        <i className="block mb-0 text-2xl rounded uil uil-phone dark:text-white"></i>
                                    </div>

                                    <div className="flex-1 ms-6">
                                        <h5 className="mb-2 text-lg font-medium dark:text-white">Phone</h5>
                                        <a href="tel:+91 9845555055" className="text-slate-400">+919845555055</a>
                                    </div>
                                </div>

                                <div className="flex mt-4">
                                    <div className="mx-auto text-center icons">
                                        <i className="block mb-0 text-2xl rounded uil uil-envelope dark:text-white"></i>
                                    </div>

                                    <div className="flex-1 ms-6">
                                        <h5 className="mb-2 text-lg font-medium dark:text-white">Email</h5>
                                        <a href="mailto:jsminerals5@gmail.com" className="text-slate-400">jsminerals5@gmail.com</a>
                                    </div>
                                </div>

                                <div className="flex mt-4">
                                    <div className="mx-auto text-center icons">
                                        <i className="block mb-0 text-2xl rounded uil uil-map-marker dark:text-white"></i>
                                    </div>

                                    <div className="flex-1 ms-6">
                                        <h5 className="mb-2 text-lg font-medium dark:text-white">Location</h5>
                                        <p className="mb-2 text-slate-400">Corporate office 7-511 mahadi mohella mominpura gulbarga Karnataka</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <h1 className="mb-3 text-2xl text-center">Our Products are Delivered to <CountUp end={40} duration={10}/>+ Countries</h1>
            <div className="flex justify-center mb-12">
                <img src={map} className="h-96"/>
            </div>
        </>
    );

}
