import React from "react";

import Image1 from "../assets/images/blog/1.jpg";
import Image2 from "../assets/images/blog/2.jpg";
import Image3 from "../assets/images/blog/3.jpg";
import bentonite from '../assets/images/bentonite.webp'
import { Link as Link2 } from 'react-router-dom';
import activated from '../assets/images/blur.jpg'
import heated from '../assets/images/heatedblur.jpg'

export default function Blog() {
  const blog = [
    {
      id: "1",
      title: "Fullers Earth (Bentonite)",
      // description:
      //   "Bentonite powder is a type of clay that is commonly used in the mining industry as a stabilizing agent.",
      image: bentonite,
    },
    {
      id: "2",
      title: "Activated Bentonite",
      description:
        "Coming Soon...",
      image: activated,
    },
    {
      id: "3",
      title: "Heated Bentonite",
      description:
        "Coming Soon...",
      image: heated,
    },
  ];

  return (
    <>
      <section className="relative md:py-24 py-16" id="blog">
        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center">
            <h6 className="text-orange-600 text-3xl font-medium uppercase mb-2">Our Products</h6>
            {/* <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">Latest News</h3>

            <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">Launch your campaign and benefit from our expertise on designing and managing conversion centered Tailwind CSS html page.</p> */}
          </div>

          <div className="flex justify-evenly  max-md:flex-col max-md:justify-center max-md:items-center">
            {blog.map((item, key) => (
              <div key={key} className="blog relative rounded-md shadow shadow-slate-200 dark:shadow-slate-800 overflow-hidden">
                <img src={item.image} alt="" className="h-96" />
                <div className="content p-6">
                  <Link2 to="#" className="text-lg hover:text-orange-600 dark:text-white dark:hover:text-orange-600 transition-all duration-500 ease-in-out font-medium">{item.title}</Link2>
                  <p className="text-slate-400 mt-3">{item.description}</p>

                  {/* <div className="mt-5">
                    <Link2 to="#" className="btn btn-link hover:text-orange-600 dark:hover:text-orange-600 after:bg-orange-600 dark:text-white transition duration-500">Read More <i className="uil uil-arrow-right"></i></Link2>
                  </div> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );

}

