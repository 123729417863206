import React from 'react';
import { Helmet } from 'react-helmet';

const Whatsapp = () => {
  return (
    <>
      <Helmet>
        {/* Add the script tag with the provided attributes */}
       
    <script 
      type="text/javascript"
      src="https://d3mkw6s8thqya7.cloudfront.net/integration-plugin.js"
      id="aisensy-wa-widget"
      widget-id="VOscqB"
    >
    </script>
	
      </Helmet>

      {/* Your component content goes here */}
      <div>
        {/* ... */}
      </div>
    </>
  );
}

export default Whatsapp;
