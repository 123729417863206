import React from "react";

import { Link } from 'react-router-dom';
import Team from "./Team";

export default function Pricing() {
    const pricing = [
        {
            id: 1,
            title: 'Basic',
            price: 10,
            user: 10,
            features: ['Complete documentation', 'Working materials in Figma', '100GB cloud storage', '500 team members']
        },
        {
            id: 2,
            title: 'Business',
            price: 99,
            user: 30,
            features: ['Complete documentation', 'Working materials in Figma', '100GB cloud storage', '500 team members']
        },
        {
            id: 3,
            title: 'Professional',
            price: 299,
            user: 100,
            features: ['Complete documentation', 'Working materials in Figma', '100GB cloud storage', '500 team members']

        },
    ]

    return (
        <>
            {/* Start */}

            <section className="relative py-16 md:py-24" id="pricing">
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h6 className="mb-2 text-base font-medium text-orange-600 uppercase">Production</h6>
                        <h3 className="mb-4 text-xl font-medium md:text-2xl dark:text-white">Production capacity per month is 5000 MT
</h3>

                        <p className="max-w-xl mx-auto text-slate-400 dark:text-slate-300">Are you ready to make the most out of your 5000 MT? Whether you're dealing with Money, Miles, Minutes, or any other resource represented by "MT".</p>
                    </div>

                    {/* <div className="flex flex-wrap">
                        {pricing.map((item, key) => (

                            <div className="w-full px-0 mt-8 md:w-1/2 lg:w-1/3 md:px-3" key={key}>
                                <div
                                    className="flex flex-col pt-8 pb-8 transition duration-500 rounded-md shadow bg-zinc-50 hover:bg-white dark:bg-gray-800 dark:hover:bg-black shadow-slate-200 dark:shadow-slate-700">
                                    <div className="px-8 pb-8">
                                        <h3 className="mb-6 text-lg font-medium md:text-xl dark:text-white">{item.title}</h3>
                                        <div className="mb-6 dark:text-white/70">
                                            <span className="relative text-2xl -top-5">$</span>
                                            <span className="text-5xl font-semibold dark:text-white">{item.price}</span>
                                            <span className="inline-block ms-1">/ month</span>
                                        </div>
                                        <p className="mb-6 text-slate-430 dark:text-slate-300">Basic features for up to {item.user} users.</p>
                                        <Link to="#" className="w-full text-white bg-orange-600 border-orange-600 rounded-md btn hover:bg-orange-700 hover:border-orange-700">Started Now</Link>
                                    </div>
                                    <div className="border-b border-slate-200 dark:border-slate-700"></div>
                                    <ul className="self-start px-8 pt-8">
                                        {item.features.map((subitem, index) => (
                                            <li className="flex items-center my-1 text-slate-400 dark:text-slate-300" key={index}>
                                                <i className="text-lg text-green-600 uil uil-check-circle me-1"></i>
                                                <span>{subitem}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div> */}
                    {/* <div className="flex justify-center mt-2 text-slate-400 dark:text-slate-300"><span className="text-orange-600">*</span>No credit card required</div> */}
                </div>
            </section>

            {/* <Team /> */}
        </>
    )


}
